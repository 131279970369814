import { NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { PagesComponent } from "./pages/pages.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  GoogleSigninButtonModule,  
} from "@abacritt/angularx-social-login";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthTokenInterceptor } from "./authentication/auth/auth-token.interceptor";
import { ToastrModule } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgSelectModule } from "@ng-select/ng-select";
import { environment } from "src/environments/environment";

import { NgxSkeletonModule } from "ngx-skeleton";
import {  MSAL_INSTANCE, MsalModule, MsalService } from "@azure/msal-angular";
import {  IPublicClientApplication,  PublicClientApplication } from "@azure/msal-browser";
import { MatDialogModule } from "@angular/material/dialog";
import { ServiceWorkerModule } from '@angular/service-worker';


@NgModule({
  declarations: [AppComponent, NavbarComponent, PagesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    MsalModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgSelectModule,  
    MatDialogModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      easing: "ease-in",
      easeTime: 1000,
    }),
    NgxSkeletonModule,

    AccordionModule.forRoot(),
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(`${environment.googleClientId}`, {
              scopes: "email profile openid",
              oneTapEnabled: false,
            }),
          }
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    DatePipe,
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
