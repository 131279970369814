<nav class="navbar navbar-expand-lg custom-navbar">
    <div class="container-fluid">
        <div class="navbar-logo-section">
            <button class="navbar-toggler" type="button" (click)="isMenuCollapsed=
                !isMenuCollapsed">&#9776;</button>
            <a class="navbar-brand m-2" [routerLink]="'.'"><img src="assets/images/navlogo.png" alt=""></a>
            <span>Project<br> Time
                Tracker</span>
        </div>
        <!-- Step 3: Toggle the value of the property when the toggler button is clicked. -->
        <!-- Step 2: Add the ngbCollapse directive to the element below. -->
        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse
            custom-collapse-center">
            <ul class="navbar-nav">
                <li class="nav-item" *ngIf="managerLoggedIn">
                    <a class="nav-link" routerLink="bill-matrix" routerLinkActive="active" (click)="isMenuCollapsed= true">Planned Billiability Matrix</a>
                </li>
                <li class="nav-item" *ngIf="managerLoggedIn">
                    <a class="nav-link" routerLink="resources" routerLinkActive="active" (click)="isMenuCollapsed= true">Resources</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="goals" routerLinkActive="active" (click)="isMenuCollapsed= true">Goals</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="job-openings" routerLinkActive="active" (click)="isMenuCollapsed= true">Openings</a>
                </li>
                <li class="nav-item">

                    <a class="nav-link" routerLink="home" routerLinkActive="active" (click)="isMenuCollapsed=
                        true"><span>Timesheet</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="skills" routerLinkActive="active" (click)="isMenuCollapsed=
                        true"><span>Skills</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'view-project-documents'" routerLinkActive="active" (click)="isMenuCollapsed=
                        true"><span>Documents</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'appraisal'" routerLinkActive="active" (click)="isMenuCollapsed=
                        true"><span>Appraisal</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="'chats'" routerLinkActive="active" (click)="isMenuCollapsed=
                    true"><span>Chats</span></a>
                </li>
                <li  class="nav-item">
                    <a class="nav-link" [routerLink]="'gen-ai'" routerLinkActive="active" (click)="isMenuCollapsed=
                    true"><span>HR Bot</span></a>

                </li>

                <li *ngIf="userRole === 'hr'" class="nav-item" ngbDropdown routerLinkActive="active">
                    <a class="nav-link" id="exitInfoDropdown" ngbDropdownToggle>
                        <span class="position-relative">Exit Info
                            <span *ngIf="isExitInfoTabVisibleForHr()" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                        </span>
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="exitInfoDropdown">
                        <button *ngIf="userRole === 'hr' && exitInfoTabVisible" (click)="isMenuCollapsed= true" ngbDropdownItem [routerLink]="['/exit_info', 'employee_status']" routerLinkActive="active-menu-item">
                            My Exit Info
                        </button>
                        <button ngbDropdownItem [routerLink]="['/exit_info', 'list_of_employees']" routerLinkActive="active-menu-item">
                            List of Employees
                        </button>
                        <button *ngIf="isExitInfoTabVisibleForHr()" ngbDropdownItem [routerLink]="['/exit_info', 'hr_feedback']" routerLinkActive="active-menu-item">
                                <span class="position-relative">HR Feedback
                                    <span class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                        <span class="visually-hidden">New alerts</span>
                                    </span>
                                </span>
                        </button>
                    </div>
                </li>

                <li *ngIf="isExitInfoTabVisibleForManager() || (userRole === 'manager' && exitInfoTabVisible)" class="nav-item" ngbDropdown routerLinkActive="active">
                    <a class="nav-link" id="exitInfoDropdown" ngbDropdownToggle>
                        <span class="position-relative">Exit Info
                            <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                        </span>
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="exitInfoDropdown">
                        <button *ngIf="userRole === 'manager' && exitInfoTabVisible" (click)="isMenuCollapsed= true" ngbDropdownItem [routerLink]="['/exit_info', 'employee_status']" routerLinkActive="active-menu-item">
                            My Exit Info
                        </button>
                        <button *ngIf="isExitInfoTabVisibleForManager()" ngbDropdownItem [routerLink]="['/exit_info', 'manager_feedback']" routerLinkActive="active-menu-item">
                                <span class="position-relative">Manager Feedback
                                    <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                        <span class="visually-hidden">New alerts</span>
                                    </span>
                                </span>
                        </button>
                    </div>
                </li>

                <li *ngIf="isExitInfoTabVisibleForIt() || (userRole === 'it' && exitInfoTabVisible)" class="nav-item" ngbDropdown routerLinkActive="active">
                    <a class="nav-link" id="exitInfoDropdown" ngbDropdownToggle>
                        <span class="position-relative">Exit Info
                            <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                        </span>
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="exitInfoDropdown">
                        <button *ngIf="userRole === 'it' && exitInfoTabVisible" (click)="isMenuCollapsed= true" ngbDropdownItem [routerLink]="['/exit_info', 'employee_status']" routerLinkActive="active-menu-item">
                            My Exit Info
                        </button>
                        <button *ngIf="isExitInfoTabVisibleForIt()" ngbDropdownItem [routerLink]="['/exit_info', 'it_feedback']" routerLinkActive="active-menu-item">
                                <span class="position-relative">It Feedback
                                    <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                        <span class="visually-hidden">New alerts</span>
                                    </span>
                                </span>
                        </button>
                    </div>
                </li>                

                <li *ngIf="isExitInfoTabVisibleForAcc() || (userRole === 'accounts' && exitInfoTabVisible)" class="nav-item" ngbDropdown routerLinkActive="active">
                    <a class="nav-link" id="exitInfoDropdown" ngbDropdownToggle>
                        <span class="position-relative">Exit Info
                            <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                <span class="visually-hidden">New alerts</span>
                        </span>
                        </span>
                    </a>
                    <div ngbDropdownMenu aria-labelledby="exitInfoDropdown">
                        <button *ngIf="userRole === 'accounts' && exitInfoTabVisible" (click)="isMenuCollapsed= true" ngbDropdownItem [routerLink]="['/exit_info', 'employee_status']" routerLinkActive="active-menu-item">
                            My Exit Info
                        </button>
                        <button *ngIf="isExitInfoTabVisibleForAcc()" ngbDropdownItem [routerLink]="['/exit_info', 'accountant_feedback']" routerLinkActive="active-menu-item">
                                <span class="position-relative">Accountant Feedback
                                    <span *ngIf="filteredFbNotCompletedEmployees.length>0" class="position-absolute top-0 start-100 translate-middle p-1 badge bg-danger border border-light rounded-circle">
                                        <span class="visually-hidden">New alerts</span>
                                    </span>
                                </span>
                        </button>
                    </div>
                </li>

                <!-- userRole === 'user' && (empStatusExitInfo || exitInfoTabVisible) -->
                <li *ngIf="isExitInfoTabVisibleForUser()" class="nav-item" routerLinkActive="active">
                    <a class="nav-link" [routerLink]="['/exit_info', 'employee_status']">
                        <span class="position-relative">Exit Info </span>
                    </a>
                </li>
                
                <li  class="nav-item">
                    <a class="nav-link" [routerLink]="'star-performer'" routerLinkActive="active" (click)="isMenuCollapsed=
                    true"><span>Star Performers</span></a>

                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="reimbursement" routerLinkActive="active" (click)="isMenuCollapsed= true">Reimbursement</a>
                </li>
            </ul>

        </div>
        <div  class="collapse  navbar-collapse custom-collapse-profile" [ngbCollapse]="isMenuCollapsed">
            <div  class="logout-profile">
            <div ngbDropdown class="d-inline-block">
                <button  type="button" class="drop-down-img" id="dropdownBasic1" ngbDropdownToggle>
                    <ng-container>
                        <div class="profile-username">
                            <div class="profile-username-body">
                                <img *ngIf="photoUrl && photoUrl !== 'null' && showImage"                              [src]="photoUrl" 
                                alt="User photo" 
                                (error)="onImageError()" 
                                />                            
                                <span class="initailsname" *ngIf="!photoUrl || photoUrl === 'null' || !showImage" >
                                    {{ getInitial(username) }}
                                </span>
                            </div>
                            <span>{{ username }}</span>
                        </div>
                      </ng-container>       
                </button>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="profile-dropdown-menu">
                    <h5>WELCOME !</h5>

                    <button ngbDropdownItem [routerLink]="navbarService.userProfileStatus?.route" (click)="isMenuCollapsed= true">
                        <i class="fa fa-user"></i><span>{{navbarService.userProfileStatus?.text}}</span>
                    </button>

                    <button ngbDropdownItem (click)="onResign()">
                        <i class="fa fa-user-xmark"></i><span>Resign</span>
                    </button>

                    <a class="nav-link dropdown-navlink" href="https://pacewisdomsolutions.sharepoint.com/:w:/s/Pacewisdom/Ea_ZMTyRofdEt87MFHNXiRoBFnSwdKPQw-W6rfmzyEzaGQ?e=6m0wPV" target="_blank" rel="noopener" (click)="isMenuCollapsed= true">
                        <button ngbDropdownItem>
                            <i class="fa fa-paperclip"></i><span>QMS & ISMS Policy</span>
                        </button>
                    </a>
                    <hr>
                    <button type="button" ngbDropdownItem (click)="openLogoutConfirmPopup()">
                        <i class="fa fa-arrow-right-from-bracket"></i>
                        <span>Logout</span>
                    </button>
                </div>
            </div>

        </div>

        </div>


    </div>
</nav>