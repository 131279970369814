import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { NotifierService } from "src/app/Toaster/notifier.service";
import { NavbarService } from "src/app/layout/navbar/navbar.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Add a BehaviorSubject to keep track of authentication status
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  role: string = "";

  private googleAuthEndpoint = "https://accounts.google.com/o/oauth2/iframerpc";
  private paceWisdomAuthEndpoint =
    `${environment.baseUrl}/timesheet/api/dj-rest-auth/google/`;
  apiUrl = `${environment.baseUrl}/timesheet/api/dj-rest-auth/google/`;
  tokenUrl = `${environment.baseUrl}/api/token/`;
  userDetailsUrl = `${environment.baseUrl}/timesheet/api/userdetails/1/`;
  personalInfoUrl = `${environment.baseUrl}/edm/api/personal_info/`;
  eidUrl = `${environment.baseUrl}/edm/api/eid_card/`;
  forgotPasswordUrl = `${environment.baseUrl}/timesheet/api/forgot_password/`;
  resetPasswordUrl = `${environment.baseUrl}/timesheet/api/reset_password/`;
  authState: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: NotifierService,
    private navbarService: NavbarService
  ) {
    // Check if the user is already authenticated
    this.isAuthenticatedSubject.next(this.isLoggedIn());
  }

  login(data: any) {
    return this.http.post(this.tokenUrl, data);
  }

  getUserDetails() {
    return this.http.get(this.userDetailsUrl);
  }
  profile() {
    this.http.get(this.userDetailsUrl).subscribe((res: any) => {
      localStorage.setItem("P1", res.user_details.user_id);
      // localStorage.setItem("profile_pic", res.user_details.profile_pic);
      localStorage.setItem("username", res.user_details.name);
      localStorage.setItem("email",res.user_details.email)
      if (res.user_details.is_lead === true && res.user_details.is_hr === true) {
        this.role = "hr";
      } else if (res.user_details.is_lead === true) {
        this.role = "manager";
      } else if (res.user_details.is_hr === true) {
        this.role = "hr";
      } else if (res.user_details.is_it === true) {
        this.role = "it";
      } else if (res.user_details.is_accounts === true) {
        this.role = "accounts";
      } else {
        this.role = "user";
      }      
      
      localStorage.setItem("role", this.role);
     
      // const intendedUrl = localStorage.getItem('intendedUrl');
      this.router.navigate(["/home"])
      this.toastr.showSuccess("SUCCESS", "Logged in successfully");
      // this.router.navigate([intendedUrl]);
      // localStorage.removeItem('intendedUrl')
    });
  }

  loginWithGoogle(token: string) {
    const body = { access_token: token };

    this.http
      .post(
        "https://projects.pacewisdom.in/timesheet/api/dj-rest-auth/google/",
        body
      )
      .subscribe(
        (res: any) => {
          localStorage.setItem("P2", res.access_token);
          this.profile();
        },
        (err) => {
          this.toastr.showError("ERROR", err.message);
        }
      );
  }

    loginWithMicrosoft(atoken: string, idtoken:string) {
    const body = { access_token: atoken, id_token: idtoken };    

    this.http
      .post(`${environment.baseUrl}/timesheet/api/microsoft/callback/verify/`,
        body
      )
      .subscribe(
        (res: any) => {
          localStorage.setItem("P2", res.access);
          this.profile();
        },
        (err) => {
          this.toastr.showError("ERROR", err.message);
          this.router.navigate(["auth/login"]);
        }
      );
  }

  logout() {
    localStorage.clear();
    this.isAuthenticatedSubject.next(false); // Set authentication status to false
  }

  isLoggedIn() {
    return localStorage.getItem("P2") != null;
  }

  // profile page service
  getPersonalInfo() {
    return this.http.get(`${this.personalInfoUrl}`);
  }
  public getEid() {
    return this.http.get(`${this.eidUrl}`, {
      observe: "response",
      responseType: "arraybuffer",
    });
  }

  forgotPassword(data:any){
    const body = { email: data.email};
    return this.http.post<any>(`${this.forgotPasswordUrl}`,body);
  }

  resetPassword(token:string,new_pwd:string,c_pwd:string){
    const body = {token: token, new_password: new_pwd, confirm_password:c_pwd};
    return this.http.post<any>(`${this.resetPasswordUrl}`,body);
  }

  fetchUserDetailsAndSetProfilePic() {
    this.getUserDetails().subscribe((res: any) => {
      this.navbarService.setProfilePic(res.user_details.profile_pic);
    });
  }
}
